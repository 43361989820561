import { HttpClient } from '@angular/common/http';
import {
  Component,
  DestroyRef,
  effect,
  Inject,
  inject,
  signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import {
  NS_CONFIG_TOKEN,
  NSNotificationModuleOptions,
  NSNotificationsComponent
} from '@bannerflow/notification';
import { UIModule } from '@bannerflow/ui';
import { interval, merge, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  imports: [UIModule, NSNotificationsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private authService = inject(AuthService);
  private destroyRef = inject(DestroyRef);
  private httpClient = inject(HttpClient);

  private user = toSignal(this.authService.user$);

  title = 'NotificationService-demo';
  currentUserId = '';
  useTestData = signal(false);

  constructor(
    @Inject(NS_CONFIG_TOKEN) private libConfig: NSNotificationModuleOptions
  ) {
    this.useTestData.set(window.location.search.includes('useTestData=true'));
    effect(
      () => {
        const user = this.user();

        this.currentUserId = user?.['userId'] ?? '';

        this.libConfig.userId$?.next(user?.['userId']);
        if (user) {
          const intervalTime = 30 * 60 * 1000; // 30 mins
          merge(interval(intervalTime), of())
            .pipe(
              switchMap(() =>
                this.authService.getAccessTokenSilently({ cacheMode: 'on' })
              ),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(accessToken => {
              this.libConfig.accessToken$.next(accessToken);
            });
        }
      },
      {
        // needs to write a signal inside lib
        allowSignalWrites: true
      }
    );
  }

  updateUseTestData(): void {
    window.location.search = `useTestData=${!this.useTestData()}`;
  }

  userIdSelected(): void {
    if (!this.currentUserId) {
      this.currentUserId = this.user()?.['userId'] ?? '';
    }
    this.libConfig.userId$?.next(this.currentUserId);
  }

  triggerTestNotification(): void {
    const url = `${environment.origins['notificationServiceApi']}/notifications/test`;
    this.httpClient.post(url, undefined).subscribe({
      next: () => console.log('NotificationCommand created'),
      error: error =>
        console.error('NotificationCommand creation failed', error)
    });
  }
}
