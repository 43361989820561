import { computed, inject, Injectable, signal } from '@angular/core';
import { NotificationDto } from '@bannerflow/types.notification-service';
import {
  ImageExportNotification,
  NotificationReceivedEvent,
  VideoExportNotification
} from '../domain';
import { FilterType } from '../domain/states.types';
import {
  downloadLink,
  isImageExportNotification,
  isVideoExportNotification
} from '../utils';
import { NSBrowserTabService } from './browser-tab.service';
import { NotificationDataService } from './notification.data.service';
import { UserService } from './user.service';

@Injectable()
export class NotificationService {
  private browserTabService = inject(NSBrowserTabService);
  private notificationsDataService = inject(NotificationDataService);
  private userService = inject(UserService);

  private _newNotification = signal<NotificationDto | undefined>(undefined);
  private _notifications = signal<NotificationDto[]>([]);
  private _numberOfUnread = signal(0);
  private currentPage = signal(1);
  private filter = signal<FilterType>('showAll');
  private itemsPerPage = signal(25);
  private totalNumberOfItems = signal(0);

  canLoadMore = computed(() => this.computeCanLoadMore());
  currentFilter = computed(() => this.filter());
  error = signal<unknown | undefined>(undefined);
  hasUnreadNotifications = computed(() => this.computeHasUnreadNotifications());
  loading = signal(false);
  newNotification = this._newNotification.asReadonly();
  notifications = computed(() => this.computeNotifications());
  numberOfUnread = this._numberOfUnread.asReadonly();

  setFilter(newFilter: FilterType): void {
    this.filter.set(newFilter);
  }

  loadNumberOfUnreadNotifications(): void {
    const userId = this.userService.userId();
    this.notificationsDataService
      .getNumberUnreadNotifications(userId)
      .subscribe({
        next: ({ count }) => {
          this.error.set(undefined);
          this._numberOfUnread.set(count);
        },
        error: error => {
          this.error.set(error);
        }
      });
  }

  loadMoreNotifications(): void {
    this.loading.set(true);

    const currentPage = this.currentPage();
    const itemsPerPage = this.itemsPerPage();
    const userId = this.userService.userId();

    this.notificationsDataService
      .loadMostRecentNotification(currentPage, itemsPerPage, userId)
      .subscribe({
        next: ({ items, paginationMetadata }) => {
          this.currentPage.set(paginationMetadata.currentPage);
          this.totalNumberOfItems.set(paginationMetadata.totalItems);
          this._notifications.update(notifications => [
            ...notifications,
            ...(items ?? [])
          ]);
          this.loading.set(false);
          this.error.set(undefined);
        },
        error: error => {
          this.loading.set(false);
          this.error.set(error);
        }
      });
  }

  loadAndPrependNotification(
    newNotificationEvent: NotificationReceivedEvent
  ): void {
    this.loading.set(true);
    this.notificationsDataService
      .getNotificationById(newNotificationEvent.notificationId)
      .subscribe({
        next: newNotification => {
          this._newNotification.set(newNotification);
          this._notifications.update(notifications => [
            newNotification,
            ...notifications.filter(({ id }) => id !== newNotification.id)
          ]);
          this.loading.set(false);
          this.error.set(undefined);

          if (
            !newNotification.read &&
            (isVideoExportNotification(newNotification) ||
              isImageExportNotification(newNotification))
          ) {
            this.autoDownload(newNotification);
          }
        },
        error: error => {
          this._newNotification.set(undefined);
          this.loading.set(false);
          this.error.set(error);
        }
      });
  }

  refreshNotifications(): void {
    this.currentPage.set(1);
    this._notifications.set([]);
    this.filter.set('showAll');
    this.loadMoreNotifications();
  }

  markAsRead(notification: NotificationDto): void {
    this.notificationsDataService.markAsRead(notification).subscribe({
      next: () => {
        this._notifications.update(notifications =>
          notifications.map(currentNotification =>
            currentNotification.id === notification.id
              ? { ...currentNotification, read: true }
              : currentNotification
          )
        );
        this._numberOfUnread.update(numberOfUnread =>
          Math.max(0, numberOfUnread - 1)
        );
        this.error.set(undefined);
      },
      error: error => {
        this.error.set(error);
      }
    });
  }

  markAllAsRead(): void {
    this.notificationsDataService.markAllAsRead().subscribe({
      next: () => {
        this._notifications.update(notifications =>
          notifications.map(currentNotification => ({
            ...currentNotification,
            read: true
          }))
        );
        this._numberOfUnread.set(0);
        this.error.set(undefined);
      },
      error: error => {
        this.error.set(error);
      }
    });
  }

  private computeNotifications(): NotificationDto[] {
    const filter = this.filter();
    const notifications = this._notifications();
    if (filter === 'showUnread') {
      return notifications.filter(({ read }) => !read);
    }
    return notifications;
  }

  private computeCanLoadMore(): boolean {
    const currentPage = this.currentPage();
    const totalNumberOfItems = this.totalNumberOfItems();
    const itemsPerPage = this.itemsPerPage();
    return currentPage * itemsPerPage < totalNumberOfItems;
  }

  private computeHasUnreadNotifications(): boolean {
    const notifications = this._notifications();
    return notifications.some(({ read }) => !read);
  }

  private autoDownload(
    notification: VideoExportNotification | ImageExportNotification
  ): void {
    if (document.hidden && !this.browserTabService.isLastTabOpened()) {
      // Do not autoDownload when tab is not focused
      return;
    }
    if (notification.content.link) {
      downloadLink(notification.content.link);
      this.markAsRead(notification);
    }
  }
}
