import { Injectable } from '@angular/core';

const LAST_TAB_ID_KEY = 'NS_TAB_ID';

@Injectable({
  providedIn: 'root'
})
export class NSBrowserTabService {
  private tabId = Math.random().toString();

  constructor() {
    localStorage.setItem(LAST_TAB_ID_KEY, this.tabId);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('blur', this.handleVisibilityChange);
  }

  private handleVisibilityChange = (): void => {
    const visibilityChange = !document.hidden;
    if (visibilityChange) {
      localStorage.setItem(LAST_TAB_ID_KEY, this.tabId);
    }
  };

  isLastTabOpened(): boolean {
    const lastTabId = localStorage.getItem(LAST_TAB_ID_KEY);
    if (!lastTabId) {
      localStorage.setItem(LAST_TAB_ID_KEY, this.tabId);
      return true;
    }
    return lastTabId === this.tabId;
  }
}
